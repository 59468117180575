
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import store from "@/store";
import { VHover, VListItem } from "vuetify/lib";
export default Vue.extend({
  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      mini:false,
      /* 默认显示的router */
      active:9,
      isAlive:true
    };
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      message_bar: "message_bar",
    }),
    items() {
      var feature_list = {
        vbe: {
          title: "VBE Study",
          to: "/vbe_input",
          icon: "mdi-feature-search",
        },
        dissolution: {
          title: "英翰智能溶出数据库",
          to: "/dissolution_input",
          icon: "mdi-feature-search",
        },
        ddi: {
          title: "DDI Study",
          to: "/ddi_input",
          icon: "mdi-feature-search",
        },
        induction_ddi: {
          title: "Induction DDI Study",
          to: "/induction_ddi_input",
          icon: "mdi-feature-search",
        },
        full_pbpk: {
          title: "AI-PBPK Study",
          to: "/full_pbpk_input",
          icon: "mdi-feature-search",
        },
        ppi: {
          title: "PPI Study",
          to: "/ppi_input",
          icon: "mdi-feature-search",
        },
        /* nasal_pbpk: {
          title: "Nasal PBPK Study",
          to: "/nasal_pbpk_input",
          icon: "mdi-feature-search",
        }, */
        inhalation: {
          title: "Inhalation Study",
          to: "/inhalation_input",
          icon: "mdi-feature-search",
        },
        fih: {
          title: "Custom Study",
          to: "/custom_input",
          icon: "mdi-feature-search",
        },
        fih_pbpk: {
          title: "AI-ADME Study",
          to: "/ai_adme_input",
          icon: "mdi-feature-search",
        },
        nca: {
          title: "NCA Study",
          to: "/NCA_input",
          icon: "mdi-feature-search",
        },
      };

      var my_items = [];

      var user = store.getters["auth/user"];
      if (user) {
        var user_entitlement = new Set();
        user.entitlement.forEach((item) => {
          user_entitlement.add(item.study_type);
        });
        /* 后端添加后删除 */
       /*  user_entitlement.add("induction_ddi") */
        /* console.log("后端添加",user_entitlement); */
        
        Object.keys(feature_list).forEach((key) => {
          if (user_entitlement.has(key)) {
            my_items.push(feature_list[key]);
          } else {
            feature_list[key].to = "/no_subscriptions";
            my_items.push(feature_list[key]);
          }
        });
        my_items.push({
          title: "View All",
          to: "/study_runs",
          icon: "mdi-widgets",
        });
      }
      return my_items;
    },
  },
  methods: {
    ...mapActions({
      signOutAction: "auth/signOut",
    }),
    signOut() {
      this.signOutAction();
    },
    reload() {
      this.isAlive = false;
      this.$nextTick( ()=>{
        this.isAlive = true
      })
    }
  },
  mounted: () => {
    console.log("defaultlayout mounted");
  },
});
