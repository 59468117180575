<!--
    Author: Huajie Liu 
    Email: jack818liu@gmail.com
-->
<template>
  <default-layout>
    <router-view></router-view>
  </default-layout>
</template>

<script Lang="ts">
import DefaultLayout from "./components/DefaultLayout.vue";
export default {
  components: { DefaultLayout },
  mounted: () => {
    console.log("app mounted");
  },
};
</script>
