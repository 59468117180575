
import Vue from 'vue'
  import DefaultLayout from '@/components/DefaultLayout.vue'
  export default Vue.extend({
    name: 'Home',

    components: {
        DefaultLayout,
    },
    mounted: () => {
      console.log('home mounted')
    }
  })
