/*
    Author: Huajie Liu 
    Email: jack818liu@gmail.com
*/
import b2osim_api from "@/services/b2osim_api";
import utilities from "@/services/utilities";
import axios from "axios";
import Router from "../router";

export default {
  //we need namespaced to allow reference action with module name 'auth'
  namespaced: true,
  state: {
    token: null,
    user: null,
  },
  getters: {
    authenticated(state) {
      console.log(!(!state.token || !state.user));
      console.log("auth");
      return !(!state.token || !state.user);
    },
    user(state) {
      return state.user;
    },
  },
  mutations: {
    UPDATE_SESSION(state) {
      b2osim_api.updateSession().then((res) => {
        console.log("UPDATE_SESISON:");
        console.log(res);
        if (res.data.token) {
          console.log("token updated");
          state.token = res.data.token;
        }
      });
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, user) {
      state.user = user;
    },
  },
  actions: {
    async signIn({ dispatch, commit, getters, rootGetters }, credentials) {
      let response = await axios.post("login", credentials);
      console.log("signin action:");
      console.log(response.data);
      if (response.data.result && !response.data.result.id) {
        utilities.show_message("error", response.data.result);
        return;
      }
      return dispatch("attempt", response.data.token);
    },
    async setToken({ dispatch, commit, state, getters, rootGetters }, token) {
        commit("SET_TOKEN", token);
    },

    async attempt({ dispatch, commit, state, getters, rootGetters }, token) {
      if (token) {
        //if we have token, set
        commit("SET_TOKEN", token);
      }
      if (!state.token) {
        //if no token, don't try to get user info
        return;
      }
      try {
        let response = await axios.get("myInfo");
        console.log(response.data);
        commit("SET_USER", response.data[0]);
      } catch (e) {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
        console.log(e);
        if (e.message) {
          utilities.show_message("error", e.message);
        } else {
          utilities.show_message("error", e);
        }
        Router.push("signin");
      }
    },
    async signOut({ commit }) {
      return axios.get("closeSession").then((res) => {
        if (res.data && res.data.status === "success") {
          commit("SET_TOKEN", null);
          commit("SET_USER", null);
          Router.push("signin");
        }
      });
    },
  },
};
