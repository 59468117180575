/*
    Author: Huajie Liu 
    Email: jack818liu@gmail.com
*/
import axios from "axios";
import store from "@/store";
import utilities from "@/services/utilities";
import Router from "../router";
import simulation from "../simulation_version";

axios.interceptors.response.use(
  function (response) {
    //console.log('headers:')
    //console.log(response.headers);
    if (response.headers["token"]) {
      //console.log("update token");
      store.dispatch("auth/setToken", response.headers["token"]);
    }
    utilities.show_response(response);
    return response;
  },
  function (error) {
    console.log(error);

    if (error.response) {
      console.log("error.response:");
      console.log(error.response);
      if (error.response.status === 401) {
        if (error.response.message) {
          utilities.show_message("error", error.response.message);
        } else if (error.response.data.message) {
          utilities.show_message("error", error.response.message);
        } else {
          utilities.show_message(
            "error",
            "The authentication failed, please try again!"
          );
        }

        store.dispatch("auth/setToken", null);
        Router.push("signin");
      } else if (error.response.data && error.response.data.message) {
        utilities.show_message("error", error.response.data.message);
      }
    } else if (error.message) {
      utilities.show_message("error", error.message);
    } else {
      utilities.show_message("error", error);
    }
    return Promise.reject(error);
  }
);

export default {
  listStudyData(params) {
    return axios.post("list_study_data", params);
  },
  deleteStudyData(id) {
    return axios.post("delete_study_data", { id: id });
  },
  saveStudyData(study_data) {
    console.log(study_data);
    return axios.post("save_study_data", { data: study_data });
  },

  listStudyRun() {
    return axios.post("list_study_run");
  },
/* 溶出api */
  listDrugDb() {
    return axios.post("list_drug_db");
  },
  getDrugDetail(id) {
    return axios.post("get_drug_detail", { id: id });
  },
/* 溶出api */
  getStudyRun(id) {
    return axios.post("get_study_run", { id: id });
  },
  deleteStudyRun(id) {
    console.log("delete study run:" + id);
    return axios.post("delete_study_run", { id: id });
  },

  getStudyInput(id) {
    return axios.post("get_study_input", { id: id });
  },
  submitCustomStudy(form_data_input) {
    form_data_input.append('ui_version',simulation.ui_version);
    utilities.show_message(
      "success",
      "The study has been submitted, it will take a while, please wait..."
    );
    return axios.post("submit_custom_study", form_data_input);
  },
  submitNCAStudy(form_data_input) {
    form_data_input.append('ui_version',simulation.ui_version);
    utilities.show_message(
      "success",
      "The study has been submitted, it will take a while, please wait..."
    );
    if (form_data_input.get("output_format")==="html") {
      return axios.post("submit_NCA_study", form_data_input);
    }else{
      return axios.post("submit_NCA_study", form_data_input,{responseType: 'blob'});
    }
  },
 
  submitStudyWithFile(input) {
    input['ui_version']=simulation.ui_version;
    utilities.show_message(
      "success",
      "The study has been submitted, it will take a while, please wait..."
    );
    return axios.post("submit_study_with_file",  input );
  },

  submitStudy(input) {
    if (input.study_type !== "ai_pbpk") {
      input['ui_version']=simulation.ui_version;
    }
    utilities.show_message(
      "success",
      "The study has been submitted, it will take a while, please wait..."
    );
    return axios.post("submit_study_run", { data: input });
  },
  listGroup() {
    return axios.post("list_group");
  },
  setCurrentGroup(group_name) {
    return axios.post("set_current_group", { group_name: group_name });
  },

  listCompany() {
    return axios.post("list_company");
  },
  setCurrentCompany(company_id) {
    return axios.post("set_current_company", { company_id: company_id });
  },
  generate_docx(run_id) {
    utilities.show_message(
      "success",
      "The request has been submitted, it will take a while, please wait..."
    );

    console.log("docx: " + run_id);
    var filename = "simulation_report.docx";
    axios
      .post("generate_docx", { id: run_id }, { responseType: "arraybuffer" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        utilities.show_message("error", err);
      });
  },

  generate_ncaDocx(ncaData) {
    utilities.show_message(
      "success",
      "The request has been submitted, it will take a while, please wait..."
    );
    console.log("ncadocx: ");
    var filename = "simulation_report_nca.docx";
    const url = window.URL.createObjectURL(new Blob([ncaData]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
  },

  generate_excel(run_id,study_type) {
    utilities.show_message(
      "success",
      "The request has been submitted, it will take a while, please wait..."
    );
    console.log("excel: " + run_id);
    var filename  = study_type +"_result.xlsx";
    axios
      .post("generate_excel", { id: run_id }, { responseType: "arraybuffer" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        utilities.show_message("error", err);
      });
  },

  updateSession() {
    return axios.get("updateSession");
  },
};
