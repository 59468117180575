/*
    Author: Huajie Liu 
    Email: jack818liu@gmail.com
*/
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/Home.vue";
import SignIn from "@/views/account/SignIn.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/signin",
    name: "signin",
    component: SignIn,
  },

  {
    path: "/",
    name: "home",
    component: Home,
  },

  {
    path: "/no_subscriptions",
    name: "no_subscriptions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/account/NoSubscriptions.vue"),
  },

 
  {
    path: "/study_runs",
    name: "study_runs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/StudyRuns.vue"),
  },

 

  {
    path: "/vbe_input",
    name: "vbe_input",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/VbeInput.vue"),
  },
  {
    path: "/vbe_input/:id",
    name: "vbe_input_id",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/VbeInput.vue"),
  },
  {
    path: "/vbe_output/:id",
    name: "vbe_output",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/VbeOutput.vue"),
  },

 /* 英翰智能溶出数据库 */
  {
    path: "/dissolution_input",
    name: "dissolution_input",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/DissolutionInput.vue"),
  },
  {
    path: "/dissolution_input/:id",
    name: "dissolution_input_id",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/DissolutionInput.vue"),
  },
  {
    path: "/dissolution_output/:id",
    name: "dissolution_output",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/DissolutionOutput.vue"),
  },

  {
    path: "/full_pbpk_input",
    name: "full_pbpk_input",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/PbpkInput.vue"),
  },

  {
    path: "/full_pbpk_input/:id",
    name: "full_pbpk_input_id",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/PbpkInput.vue"),
  },
  {
    path: "/full_pbpk_output/:id",
    name: "full_pbpk_output",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/PbpkOutput.vue"),
  },

  {
    path: "/ddi_input",
    name: "ddi_input",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/DdiInput.vue"),
  },
 
  {
    path: "/ddi_input/:id",
    name: "ddi_input_id",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/DdiInput.vue"),
  },
  {
    path: "/ddi_output/:id",
    name: "ddi_output",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/DdiOutput.vue"),
  },


  {
    path: "/induction_ddi_input",
    name: "induction_ddi_input",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/InductionDdiInput.vue"),
  },
 
  {
    path: "/induction_ddi_input/:id",
    name: "induction_ddi_input_id",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/InductionDdiInput.vue"),
  },
  {
    path: "/induction_ddi_output/:id",
    name: "induction_ddi_output",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/InductionDdiOutput.vue"),
  },


  {
    path: "/ppi_input",
    name: "ppi_input",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/PpiInput.vue"),
  },
 
  {
    path: "/ppi_input/:id",
    name: "ppi_input_id",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/PpiInput.vue"),
  },
  {
    path: "/ppi_output/:id",
    name: "ppi_output",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/PpiOutput.vue"),
  },
   {
    path: "/custom_input",
    name: "custom_input",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/CustomInput.vue"),
  },
 
  {
    path: "/custom_input/:id",
    name: "custom_input_id",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/CustomInput.vue"),
  },
  {
    path: "/custom_output/:id",
    name: "custom_output",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/CustomOutput.vue"),
  },
 
  {
    path: "/NCA_input",
    name: "NCA_input",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/NCAInput.vue"),
  },
  {
    path: "/NCA_input/:id",
    name: "NCA_input_id",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/NCAInput.vue"),
  },
  {
    path: "/NCA_output",
    name: "NCA_output",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/NCAOutput.vue"),
  },

  {
    path: "/nasal_pbpk_input",
    name: "nasal_pbpk_input",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/NasalPbpkInput.vue"),
  },
 
  {
    path: "/nasal_pbpk_input/:id",
    name: "nasal_pbpk_input_id",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/NasalPbpkInput.vue"),
  },
  {
    path: "/nasal_pbpk_output/:id",
    name: "nasal_pbpk_output",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/NasalPbpkOutput.vue"),
  },
 

  {
    path: "/mini_pbpk_input",
    name: "mini_pbpk_input",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/MiniPbpkInput.vue"),
  },
 
  {
    path: "/mini_pbpk_input/:id",
    name: "mini_pbpk_input_id",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/MiniPbpkInput.vue"),
  },
  {
    path: "/mini_pbpk_output/:id",
    name: "mini_pbpk_output",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/MiniPbpkOutput.vue"),
  },
 

  {
    path: "/inhalation_input",
    name: "inhalation_input",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/InhalationInput.vue"),
  },
  {
    path: "/inhalation_input/:id",
    name: "inhalation_input_id",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/InhalationInput.vue"),
  },
  {
    path: "/inhalation_output/:id",
    name: "inhalation_output",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/InhalationOutput.vue"),
  },

  {
    path: "/ai_adme_input",
    name: "ai_adme_input",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/AIADMEInput.vue"),
  },
  {
    path: "/ai_adme_input/:id",
    name: "ai_adme_input_id",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/AIADMEInput.vue"),
  },
  {
    path: "/ai_adme_output/:id",
    name: "ai_adme_output",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/study/AIADMEOutput.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  console.log(store);
  console.log(store.getters["auth/authenticated"]);
  if (to.name !== "signin" && !store.getters["auth/authenticated"])
    next({ name: "signin" });
  else next();
});

export default router;
