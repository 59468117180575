<!--
    Author: Huajie Liu 
    Email: jack818liu@gmail.com
-->
<template>
  <div
    class="ma-0 pa-0 d-flex align-center"
    :style="{
      height: '100%',
      width: '100%',
      'background-image': 'url(./dna_background.jpg)',
    }"
  >
    <v-card class="mx-auto px-6 py-8" width="500px">
      <v-img  :aspect-ratio="16/9" height="150" contain src="/logo-big.jpg"></v-img>
      <v-form  @submit.prevent="onSubmit">
        <v-text-field
          v-model="form.username"
          type="text"
          name="email"
          label="Email"
          class="mb-2"
          :rules="[required]"
          clearable
        ></v-text-field>
        <v-text-field
          v-model="form.password" 
          :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show_password ? 'text' : 'password'"
          name="password"
          label="Password"
          :rules="[required]"
          hint="At least 8 characters"
          counter
          clearable
          @click:append="show_password = !show_password"
        ></v-text-field>
        <v-btn
          :disabled="!form"
          color="primary"
          size="large"
          type="submit"
          :loading="loading"
          block
          class="mt-6"
        >
          Sign In
        </v-btn>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import utilities from "@/services/utilities";
import { mapActions } from "vuex";
import store from "@/store";
export default {
  name: "signin",
  data() {
    return {
      show_password: false,
      form: {
        username: "",
        password: "",
      },
      loading: false
    };
  },
  methods: {
    ...mapActions({
      signIn: "auth/signIn",
    }),
    onSubmit() {
      this.loading = true;
      this.signIn({ user: this.form })
        .then((res) => {
          console.log("login");
          if (store.getters["auth/authenticated"]) {
            this.$router.push({
              name: "study_runs",
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
        this.loading = false
    },
    required(v) {
      return !!v || 'Field is required'
    },
  },
};
</script>
