/*
    Author: Huajie Liu 
    Email: jack818liu@gmail.com
*/
import store from "@/store";

export default {
  show_message(m_type, msg) {
    if (msg) {
      store.commit("SET_MESSAGE", {
        show_message: true,
        message: msg,
        msg_type: m_type,
      });
    } else {
      console.log("got empty message in show_message with type " + m_type);
      store.commit("SET_MESSAGE", {
        show_message: true,
        message: "system internal failed, please try again later!",
        msg_type: "error",
      });
    }
  },
  show_response(res) {
    if (res.data && res.data.status && res.data.message) {
      this.show_message(res.data.status, res.data.message);
    }
  },

  show_error(res) {
    if (res.data && res.data.status && res.data.message) {
      this.show_message(res.data.status, res.data.message);
    } else {
      console.log("show_error:");
      console.log(res);
      this.show_message(
        "error",
        "System internal failed, please try again later. "
      );
    }
  },

  getSections(data) {
    var sections = [] as any;
    Object.entries(data).forEach(([sec_name, sec_content]) => {
      console.log(sec_name, sec_content);
      if (typeof sec_content !=='string') {
        //skip study_name and study_type
        var section = {} as any;
        var attributes = {} as any;
        var attribute_items = [] as any;
        var attribute_header = [] as any;
        var tables = [] as any;
        Object.entries(sec_content).forEach(([key, value]) => {
          console.log(key, value);
          if (key != "__charts__") {
            //skip __charts__ for now

            if (Array.isArray(value)) {
              if (value.length > 0) {
                var table_header = Object.keys(value[0]);
                if (value[0]._order_) {
                  table_header = value[0]._order_;
                }
                var table_items = [] as any;
                value.forEach((item) => {
                  console.log(item);
                  var cells = [];
                  for (var i = 0; i < table_header.length; i++) {
                    cells.push(item[table_header[i]]);
                  }
                  table_items.push(cells);
                });
                var table = {} as any;
                table["header"] = table_header;
                table["items"] = table_items;
                tables.push(table);
              }
            } else {
              attribute_header = Object.keys(value);
              var item = [];
              if (!value.hasOwnProperty("name")) {
                attribute_header.unshift("name");
                value["name"] = key;
              }
              if (!value.hasOwnProperty("notes")) {
                attribute_header.push("notes");
                value["notes"] = "";
              }
              console.log(attribute_header);
              console.log(attribute_header);
              if (value._order_) {
                attribute_header = value._order_;
              }
              for (var i = 0; i < attribute_header.length; i++) {
                item.push(value[attribute_header[i]]);
              }
              attribute_items.push(item);
            }
          }
        });
        attributes["header"] = attribute_header;
        attributes["items"] = attribute_items;
        section["attr"] = attributes;
        section["tables"] = tables;
        section["name"] = sec_name;
        sections.push(section);
      }
    });
    console.log("==============sections=================");
    console.log(sections);
    return sections;
  },
};
